import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./Pages/dashboard/Dashboard"));
const DataTableUserList = lazy(() => import("./Pages/users/DataTableUserList"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Login = lazy(() => import("./user-pages/Login"));

//FAQ
const ListFAQ = lazy(() => import("./Pages/FAQ/ListFAQ.js"));
const AddFAQ = lazy(() => import("./Pages/FAQ/AddFAQ.js"));

//CMS
const Features = lazy(() => import("./Pages/cms/features/ListFeatures"));

//Social links
const SocialMediaLinks = lazy(() => import("./Pages/socialLinks/SocialMediaLinks"));

//Settings
const SetPercentages = lazy(() => import("./Pages/settings/components/SetPercentages.js"));
const SetTokenAddress = lazy(() => import("./Pages/settings/components/SetTokenAddress.js"));
const SetUSDTAddress = lazy(() => import("./Pages/settings/components/SetUSDTAddress.js"));
const SetTokenPrice = lazy(() => import("./Pages/settings/components/SetTokenPrice.js"));
const TransferOwnership = lazy(() => import("./Pages/settings/components/TransferOwnership.js"));
const RegisterUserByAdmin = lazy(() => import("./Pages/settings/components/RegisterUserByAdmin.js"));
const ReTopupUserByAdmin = lazy(() => import("./Pages/settings/components/ReTopupUserByAdmin.js"));
const UpdateWalletFee = lazy(() => import("./Pages/settings/components/UpdateWalletFee.js"));
const UpdateFeePercentage = lazy(() => import("./Pages/settings/components/UpdateFeePercentage.js"));
const UnsoldTokenUsdt = lazy(() => import("./Pages/settings/components/UnsoldTokenUsdt.js"));
const UnsoldTokenXCCoin = lazy(() => import("./Pages/settings/components/UnsoldTokenXCCoin.js"));
const AdminPercentAddress = lazy(() => import("./Pages/settings/components/AdminPercentAddress.js"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Dashboard */}
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        {/* Users */}
        <ConditionRoute
          path="/users"
          component={DataTableUserList}
          type={"private"}
        />

        {/* FAQ */}
        <ConditionRoute
          path="/cms/faq/list"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/cms/faq/add"
          component={AddFAQ}
          type={"private"}
        />

        {/* CMS */}
        {/* FEATURES */}
        <ConditionRoute
          path="/cms/features"
          component={Features}
          type={"private"}
        />

        {/* Settings */}
        <ConditionRoute
          path="/settings/update/percentages"
          component={SetPercentages}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/tokenadd"
          component={SetTokenAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/usdtadd"
          component={SetUSDTAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/tokenprice"
          component={SetTokenPrice}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/transfer/ownership"
          component={TransferOwnership}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/wallet/fee"
          component={UpdateWalletFee}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/fee/percentage"
          component={UpdateFeePercentage}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/unsold/token/amount"
          component={UnsoldTokenUsdt}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/unsold/xcc/amount"
          component={UnsoldTokenXCCoin}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/Admin/address"
          component={AdminPercentAddress}
          type={"private"}
        />

        {/* Social media links */}
        <ConditionRoute
          path="/cms/social/links"
          component={SocialMediaLinks}
          type={"private"}
        />

        {/* Admin */}
        <ConditionRoute
          path="/ChangePassword"
          component={ChangePassword}
          type={"private"}
        />

        <ConditionRoute
          path="/login"
          component={Login}
          type={"auth"}
        />

        <ConditionRoute
          path="/register/user"
          component={RegisterUserByAdmin}
          type={"private"}
        />
        <ConditionRoute
          path="/topup/user"
          component={ReTopupUserByAdmin}
          type={"private"}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
